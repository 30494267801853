import { useCallback } from 'react'
import useNavigation from './useNavigation'
import { openNewTab } from '@/utils'

export default function useEventAction(data: haiper.MarketingEvent | null) {
  const action = data?.action ?? null
  const navigation = useNavigation()

  const handleClick = useCallback(
    async (e: any) => {
      if (data?.redirect_url) {
        openNewTab(data.redirect_url)
      } else if (!action || action?.type === 'open_event') {
        navigation.push(`/event?evt=${data?.event_id}`)
      } else if (action?.type === 'open_url') {
        navigation.push(action.payload?.url)
      } else if (action?.type === 'custom') {
        await action?.payload?.onClick?.()
      }
    },
    [action, navigation, data],
  )

  return {
    handleClick,
  }
}
