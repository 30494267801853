'use client'

import Dialog from '@/components/dialog'
import { cls, whisper } from '@/utils'
import { useCallback } from 'react'
import usePopups from '@/hooks/usePopups'
import CheckInStreakChallenge from '../check-in-streak-challenge'
import WinMore from '../check-in-streak-challenge/win-more'
import { DISABLE_CHECKIN } from '@/constants'

export function CheckinDialog() {
  const { showCheckinDialog, closeCheckinDialog } = usePopups()

  const handleClose = useCallback(() => {
    closeCheckinDialog()
  }, [closeCheckinDialog])

  return (
    <Dialog
      open={showCheckinDialog && !DISABLE_CHECKIN}
      title='Credit Rewards'
      titleClassName='font-bold pb-2'
      className={cls(
        'w-screen max-w-[100vw] md:w-[642px] p-4 h-screen md:h-auto max-h-[calc(100vh-64px)] bg-surface-subdued border-none md:border-border',
      )}
      footer={null}
      onCancel={handleClose}
      onOpenChange={handleClose}
    >
      <div className='relative flex flex-col items-center h-full overflow-y-auto no-scrollbar'>
        <div className='relative flex flex-col gap-4 pt-2 max-w-[642px]'>
          <CheckInStreakChallenge className='bg-surface p-4 rounded-xl' />
          <WinMore className='pb-0 bg-surface p-4 rounded-xl' onClose={handleClose} />
        </div>
      </div>
    </Dialog>
  )
}
