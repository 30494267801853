import { cls } from '@/utils'
import { CSSProperties, HTMLAttributes, useMemo } from 'react'
import MultiMedia from '../multi-media'

export interface ShadowImageCardProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  maskColor?: string
  variant?: 'primary' | 'default'
  maskDirection?: 'left' | 'bottom'
  cover: string
  thumbnail?: string
  alt: string
}

export const tailwindSafeList = ['from-[#5F392E]', 'from-[#AD6393]', 'from-[#88837B]']

export default function ShadowImageCard({
  className,
  maskDirection,
  maskColor,
  onClick,
  cover,
  thumbnail,
  alt,
  ...props
}: ShadowImageCardProps) {
  const showMask = maskColor && maskColor !== 'transparent'

  const maskStyle: CSSProperties = useMemo(() => {
    return {
      '--mask-from': maskColor,
      '--mask-to': 'transparent',
    } as any
  }, [maskColor])

  return (
    <div {...props} className={cls('z-0 absolute inset-0', className)} aria-label='shadow image card'>
      {showMask && (
        <div
          className={cls(
            'z-10 absolute inset-0 from-[23.8%] to-[68.6%] bg-gradient-to-ri from-mask-from to-mask-to pointer-events-none',
          )}
          aria-label='mask'
          style={maskStyle}
        />
      )}
      <div
        className={cls('z-0 absolute inset-y-0 right-0 bg-black/80', showMask ? 'w-[92%]' : 'w-full')}
        aria-label='image'
      >
        <MultiMedia
          media_url={cover}
          thumbnail_url={thumbnail ?? ''}
          className='w-full h-full object-cover object-center'
          playClassName='hidden'
          onClick={onClick}
        />
      </div>
    </div>
  )
}
