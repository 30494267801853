'use client'

import { cls, formatTimeRange, whisper } from '@/utils'
import Button from '@/components/button'
import IconArrowTopRight from '@haiper/icons-svg/icons/outline/arrow-top-right.svg'
import { HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react'
import ShadowImageCard, { ShadowImageCardProps } from '@/components/shadow-image-card'
import useAmplitude from '@/hooks/useAmplitude'
import useEventAction from '@/hooks/useEventAction'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import { readedEventsAtom } from '@/atoms'

export interface EventCardProps
  extends HTMLAttributes<HTMLDivElement>,
    Pick<ShadowImageCardProps, 'variant' | 'className'> {
  data: haiper.MarketingEvent
  source: string
  hideButton?: boolean
  hideStatus: boolean
}

/**
 * EventCard component
 * Event card is a card that displays an event, it usually contains an image, title, and description.
 * Also, it can contain a button to redirect to the event page.
 * Besides, it can contain a mask gradient background image on the left to make the text more readable.
 */
export default function EventCard({
  className,
  data,
  source,
  hideStatus,
  variant = 'default',
  hideButton,
  onClick,
  onMouseEnter,
  onMouseLeave,
  ...props
}: EventCardProps) {
  const showTimeRange = !data?.redirect_url
  const timeRangeStr = useMemo(() => {
    if (!showTimeRange) return ''
    return formatTimeRange(data.start_time, data.end_time)
  }, [data, showTimeRange])

  const readedEvents = useAtomValue(readedEventsAtom)

  // Show new badge if the event is created within 2 days
  const showBadgeNew = useMemo(() => {
    return (
      dayjs().diff(dayjs(data?.start_time), 'day') < 2 &&
      data?.status === 'ongoing' &&
      !readedEvents.includes(data.event_id)
    )
  }, [data, readedEvents])

  const trackingEventParams = useMemo(() => {
    return {
      event_id: data.event_id,
      source,
    }
  }, [data, source])

  const { track } = useAmplitude()

  useEffect(() => {
    track('view:event:card', trackingEventParams)
  }, [track, trackingEventParams])

  const { handleClick: handleEventClick } = useEventAction(data)

  const handleClick = useCallback(
    async (e: any) => {
      track('click:event:card', trackingEventParams)
      if (onClick) {
        onClick?.(e)
        return
      }

      handleEventClick?.(e)
    },
    [handleEventClick, onClick, track, trackingEventParams],
  )

  const showAbstract = !!data?.abstract

  return (
    <div
      {...props}
      className={cls('@container relative z-0 rounded-lg overflow-hidden border cursor-pointer h-30 w-70', className)}
      onClick={handleClick}
    >
      <ShadowImageCard
        className='z-0'
        variant={variant}
        cover={data.cover_video_url ?? data.cover_image_url ?? ''}
        thumbnail={data.cover_image_url}
        alt='shadow image card'
        maskDirection='left'
        maskColor={data?.mask_color}
        onClick={handleClick}
      />
      <div
        className='z-10 text-text-on-color absolute inset-0 p-2 @sm:py-3 @sm:px-4 flex flex-col justify-between h-full pointer-events-none'
        aria-label='foreground'
      >
        <div className='w-full flex flex-col gap-0.5 @sm:gap-2'>
          <span className='text-heading-xl font-bold leading-[normal]'>{data.title}</span>
          {showAbstract && <span className='text-body-md leading-6 tracking-15'>{data.abstract}</span>}
          {timeRangeStr && <span className='text-body-md leading-6 tracking-15 font-extralight'>{timeRangeStr}</span>}
        </div>
        {hideButton ? null : (
          <Button variant='outline' className='h-7 w-max rounded-full px-3 py-1 gap-0 text-text pointer-events-auto'>
            <div className='flex items-center'>
              <span className='px-1'>Details</span>
              <IconArrowTopRight className='text-icon size-5' />
            </div>
          </Button>
        )}
      </div>
      {showBadgeNew && (
        <div className='z-20 pointer-events-none absolute top-2 right-2'>
          <div className='bg-icon-critical text-text-on-color px-2 py-1 h-7 rounded-md tracking-15 flex items-center justify-center text-body-md font-bold'>
            <span className='px-1'>NEW</span>
          </div>
        </div>
      )}
    </div>
  )
}
